.start {
  height: 100vh;
  color: white;
  display: flex;

  &__text {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: center;
    line-height: 5rem;
    font-family: 'Patrick Hand', cursive;
    justify-content: end;
  }

}

.container__video video {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
}

.container__video::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-color: rgba(0,0,0,0.6);
}