.mobile__start {
  height: 100vh;
  color: white;
  display: flex;

  &__text {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: end;
    line-height: 2rem;
    font-family: 'Patrick Hand', cursive;
    justify-content: end;
    margin-bottom: 1rem;
  }

  .container__video video {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover;
  }

  .container__video::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.mobile__logo {
  position: absolute;
  width: 5rem;
  top: 1rem;
  left: 1rem;
}
