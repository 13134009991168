.news {
  height: 100vh;
  color: white;
  display: flex;

  &__text {
    font-size: 1.35rem;
    margin: auto;
    width: 60%;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    object-fit: contain;
  }
}