.mobile__contact {
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: end;

  &__container {
    background-color: black;
    text-align: center;

    &__text {
      margin-top: 1rem;
      font-size: 1.5rem;
      p {
        margin-bottom: 1rem;
      }
    }

    &__footer {
      padding-top: 1rem;

      &__single {
        margin-bottom: 1rem;

        a {
          text-decoration: none;
          cursor: pointer;
          color: white;
        }

        &__icons {
          margin-bottom: 0.5rem;
          color: deepskyblue;
          cursor: pointer;
        }

        &__icons:hover {
          color: white;
        }
      }
    }

  }
}