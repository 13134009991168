.contact {
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: end;

  &__image {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      object-fit: cover;
  }

  &__container {
    background-color: black;
    width: 100%;

    &__text {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
      text-align: center;
    }

    &__footer {

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__single {
        text-align: center;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;

        a {
          text-decoration: none;
          cursor: pointer;

        }

        p {
          color: white;
          font-size: 1rem;
        }

        &__icons {
          font-size: 1.5rem;
          color: deepskyblue;
          cursor: pointer;
          margin-bottom: 1.25rem;
        }

        &__icons:hover {
          color: white;
        }
      }
    }

  }
}