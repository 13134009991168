.training {
  height: 100vh;
  color: white;
  display: flex;

  &__text {
    font-size: 3rem;
    margin: auto;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover;
  }
}