.navbar {
  padding: 1.25rem;
  background-color: rgba(0,0,0,.4);
  font-size: 1.5rem;
  font-weight: bold;
  position: fixed;
  width: 100vw;

  &__logo {
    width: 6rem;
    height: auto;
  }

  &__container {
    display: flex;
    justify-content: space-between;

    &__right {
      width: 95%;
      display: flex;
      margin-left: 2rem;
      flex-direction: column;
      justify-content: center;

      &__links {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__single {
          color: deepskyblue;
          cursor: pointer;
        }

        &__single:hover {
          color: white;
        }
      }

      &__name {
        margin-top: 1rem;
        color: white;
        text-align: center;

        p {
          display: inline-block;
          word-spacing: 1rem;
          margin-bottom: 0.25rem;
        }

        :first-child {
          margin-right: 1rem;
        }

      }
    }
  }
}


