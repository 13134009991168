.mobile__aboutUs {
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    width: 90%;
  }

}