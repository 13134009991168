* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  margin: 0;
}

.container {
  width: 70%;
  margin: 0 auto;
}

.mobile__container {
  width: 90%;
  margin: 0 auto;
}


